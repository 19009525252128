import React, { useEffect } from 'react';
import Card from '../Card/Card';
import './FeaturedProducts.scss';
import useFetch from '../../hooks/useFetch';
import { Puff } from 'react-loader-spinner';
import FeaturedProductsDesc from './FeaturedProductsDesc';
import NewInDesc from './NewInDesc';

const FeaturedProducts = ({type}) => {

  const {data, loading, error} = useFetch(
    `/products?populate=*&[filters][type][$eq]=${type}`
  );

  useEffect(() => {
      // Scroll to the top of the page when the component mounts
      window.scrollTo(0, 0);
  }, []);

  return (
    <div className='featuredProducts'>
        <div className='top'>
            <h1>{type} Styles</h1>
            {type === 'featured' ? 
              <FeaturedProductsDesc /> 
              :
              <NewInDesc />
            }
        </div>
        <div className='bottom'>
            {error 
              ? "Something went wrong!" 
              : loading 
              ? 
              <div className='loading'>
                <Puff
                  height="80"
                  width="80"
                  radius={1}
                  color="#757575"
                  ariaLabel="puff-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
              : data?.map(item => <Card item={item} key={item.id} />)}
        </div>
    </div>
  )
}

export default FeaturedProducts