import React, { useState } from 'react';
import './Contact.scss';
import { AiOutlineInstagram } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

const Contact = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please enter a valid email.");
      return;
    }

    const templateParams = { email, message: 'Newsletter Signup' };

    emailjs.send(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,  // Replace with your Service ID
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID, // Replace with your Template ID
      templateParams,
      process.env.REACT_APP_EMAILJS_PUBLIC_KEY  // Replace with your Public Key
    )
    .then(() => {
      setSubmitted(true);
      toast.success("Thanks for signing up!");
    })
    .catch((error) => {
      toast.error("Something went wrong. Please try again.");
      console.error(error);
    });
  };

  return (
    <div className='contact'>
      <div className='wrapper'>
        <span>OUR NEWSLETTER:</span>
        <div className="mail">
          {submitted ? (
            <p>Thanks for signing up!</p>
          ) : (
            <form onSubmit={sendEmail}>
              <label htmlFor="email">
                <input
                  type="email"
                  placeholder='Enter your e-mail...'
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </label>
              <button type="submit">Join Us</button>
            </form>
          )}
        </div>
        <div className="icons">
          <Link target="_blank" to="https://instagram.com/theartfulstylist?igshid=MzRlODBiNWFlZA==">
            <AiOutlineInstagram />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Contact;
