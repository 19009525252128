import React, { useEffect, useState } from 'react'
import "./Wardrobe.scss";
import { ImBin } from "react-icons/im"
import { Link } from 'react-router-dom';
import useWardrobe from '../../pages/WardrobePage/useWardrobe';
import { userData } from '../../helpers';
import axios from 'axios';
import { toast } from 'react-toastify';
import { IKImage } from 'imagekitio-react';
import getBaseUrl from '../../lib/getBaseUrl';

const Wardrobe = ({open, setOpen, wardrobe, removeFromWardrobe}) => {

  const totalPrice = () => {
    let total = 0
    wardrobe.forEach(item => total += item.price);
    return total.toFixed(2);
  }

  const baseUrl = getBaseUrl();
  
  return (
    <>
      <div className='wardrobe'>
          <h1>My wardrobe</h1>
          <div className='adjustable-height'>
              {wardrobe?.map(item => (
                  <div className='link' key={item.id}>
                    <Link className='item' to={`${baseUrl}/product/${item.id}`} onClick={() => setOpen(!open)}>
                      {item?.attributes?.img?.data?.map((item, key) => (
                          <IKImage 
                              src={item?.img} 
                              alt="" 
                              className={key === 0 ? "mainImg" : "secondImg"}
                          />
                      ))}
                      <IKImage src={item?.img} alt="" />
                      <div className='details'>
                          <h1>{item?.title}</h1>
                          <p>{item?.desc?.substring(0, 100)}...</p>
                          {item?.price !== 0 && <div className="price">From: £{item?.price}</div>}
                      </div>
                    </Link>
                    <ImBin className="delete" 
                      onClick={(e) => {
                        e.stopPropagation();
                        removeFromWardrobe({
                          wardrobeItemId: item?.id,
                          index: item?.id,
                          id: item?.id
                        })
                      }}
                    />
                  </div>
              ))}
              {wardrobe.length === 0 && <>Your wardrobe is empty</>}
          </div>
          <div className="total">
              <span>Potential Total Cost</span>
              <span>£{totalPrice()}</span>
          </div>
          <div className="d-block">
              <Link className='link' to='/wardrobe'>Go to Wardrobe</Link>
              {/* <div className="reset" onClick={() => dispatch(resetWardrobe())}>Clear out wardrobe</div> */}
          </div>
      </div>
      <div className="overlay" onClick={() => setOpen(!open)}></div>
    </>
  )
}

export default Wardrobe