import React, { useEffect, useState } from 'react'
import { useParams, useLocation } from 'react-router-dom';
import List from '../../components/List/List';
import './Products.scss';
import './Search.scss';
import useFetch from '../../hooks/useFetch';
import { AiOutlineClose } from "react-icons/ai"
import { RxHamburgerMenu } from "react-icons/rx"
import { Puff } from 'react-loader-spinner';
import { useOpen } from '../../context/OpenProvider';
import { IKImage } from 'imagekitio-react';

const Products = () => {
  const { open, setOpen } = useOpen();
  const catId = parseInt(useParams().id);
  const [maxPrice, setMaxPrice] = useState(1000);
  const [sort, setSort] = useState('asc');
  const [selectedSubCats, setSelectedSubCats] = useState([]);
  const [query, setQuery] = useState("");
  const location = useLocation();
  const categoryTitle = location?.state?.title;

  const { data, loading, error } = useFetch(`/sub-categories?[filter][categories][id][$eq]=${catId}`)

  const handleChange = (e) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    setSelectedSubCats(
      isChecked
        ? [...selectedSubCats, value]
        : selectedSubCats.filter((item) => item !== value)
    );
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='products'>
      {loading ?
        <div className='loading'>
          <Puff
            height="80"
            width="80"
            radius={1}
            color="#757575"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </div>
        :
        <>
          <button className="search-filter-toggle" onClick={() => setOpen(!open)}><RxHamburgerMenu /></button>
          {open &&
            <>
              <div className="left">
                <div className="filterItem">
                  <div className="search-header">
                    <h2>Search Products</h2>
                    <div className="close" onClick={() => setOpen(!open)}><AiOutlineClose /></div>
                  </div>
                  <input type="text" placeholder="Search Outfit..." className="search" onChange={(e) => setQuery(e.target.value)} />
                </div>
                {catId !== 1 ?
                  <div className="filterItem">
                    <h2>Product Categories</h2>
                    {data?.map(item => (
                      <div className="inputItem" key={item.id}>
                        <input type="checkbox" id={item.id} value={item.id} onChange={handleChange} />
                        <label htmlFor={item.id}>{item?.attributes?.title}</label>
                      </div>
                    ))}
                  </div> : null}
                <div className="filterItem">
                  <h2>Filter by price</h2>
                  <div className="inputItem flex">
                    <span>0</span>
                    <input type="range" className="slider" min={0} max={1000} onChange={(e) => setMaxPrice(e.target.value)} />
                    <span>{maxPrice}</span>
                  </div>
                </div>
                <div className="filterItem">
                  <h2>Sort by</h2>
                  <div className="inputItem">
                    <input type="radio" id="asc" value="asc" name="price" onChange={(e) => setSort("asc")} />
                    <label htmlFor="asc">Price (Lowest first)</label>
                  </div>
                  <div className="inputItem">
                    <input type="radio" id="desc" value="desc" name="price" onChange={(e) => setSort("desc")} />
                    <label htmlFor="desc">Price (Highest first)</label>
                  </div>
                </div>
              </div>
              <div className="overlay" onClick={() => setOpen(!open)}></div>
            </>
          }
          <div className="right">

            {loading ? (
              <div className='loading'>
                <Puff
                  height="80"
                  width="80"
                  radius={1}
                  color="#757575"
                  ariaLabel="puff-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                />
              </div>
            ) : (
              <>
                <div className="position-relative overlay-container">
                  <div className="centered-content">
                    <h1>{categoryTitle}</h1>
                  </div>
                  <IKImage
                    className="catImg"
                    src="https://ik.imagekit.io/86jsihxqm/PAC10228.jpg?updatedAt=1742336304185"
                    transformation={[
                        { width: 1200, height: 1200, quality: 50, format: "auto" }
                    ]}
                    loading="lazy"
                    alt=""
                  />
                </div>
                <List catId={catId} maxPrice={maxPrice} sort={sort} subCats={selectedSubCats} query={query} />
              </>
            )}
          </div>
        </>
      }
    </div>
  )
}

export default Products