import { useEffect, useState } from 'react';
import { setToken } from '../../lib/auth';
import { fetcher } from '../../lib/api';
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { storeUser } from "../../helpers";
import './ResetPassword.scss';

const initialUser = { code: "", password: "", passwordConfirmation: "" };

const ResetPassword = () => {
  const [user, setUser] = useState(initialUser);
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');

  const handleChange = ({ target }) => {
    const { name, value } = target;
    setUser((currentUser) => ({
      ...currentUser,
      [name]: value,
    }));
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    await axios.post(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
      code: code, // code contained in the reset link of step 3.
      password: user.password,
      passwordConfirmation: user.passwordConfirmation,
    })
    .then(response => {
      if(response) {
        toast.success("Your user's password has been reset.", {
          hideProgressBar: true,
        });
        navigate("/signin");
      }
    })
    .catch(error => {
      const errorMessageDisplay = error.response.data.error.message;

      toast.error(
        errorMessageDisplay, {
          hideProgressBar: true,
        }
      );
    });
  };

  return (
    <div className="reset">
      <h1>
        <span>
          Reset Password
        </span>
      </h1>
      <form>
        <div className="container">
          <div className="flex flex-col mb-4 md:w-full">
            <label className="d-none font-bold text-lg mb-2" htmlFor="code">
              Code
            </label>
            <input
              type="hidden"
              name="code"
              value={code}
              onChange={handleChange}
              placeholder="Enter code"
              required
            />
          </div>
          <div className="flex flex-col mb-4 md:w-full">
            <label className="font-bold text-lg mb-2" htmlFor="password">
              Pasword
            </label>
            <input
              type="password"
              name="password"
              value={user.password}
              onChange={handleChange}
              placeholder="Enter password"
              required
            />
          </div>
          <div className="flex flex-col mb-4 md:w-full">
            <label className="font-bold text-lg mb-2" htmlFor="passwordConfirmation">
              Password Confirmation
            </label>
            <input
              type="password"
              name="passwordConfirmation"
              value={user.passwordConfirmation}
              onChange={handleChange}
              placeholder="Enter password confirmation"
              required
            />
          </div>
          <button
            className="submitbtn"
            type="submit"
            onClick={handleForgotPassword}
          >
            Sumbit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;