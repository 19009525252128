import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const ConnectCallback = () => {
  const [text, setText] = useState('Loading...');
  const location = useLocation();
  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Successfully logged with the provider
    // Now logging with strapi by using the access_token (given by the provider) in props.location.search

    const url = `${process.env.REACT_APP_API_URL}/auth/${params.providerName}/callback${location.search}`

    if (params) {
      fetch(url)
      .then(res => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then(res => res.json())
      .then(res => {
        // Successfully logged with Strapi
        // Now saving the jwt to use it for future authenticated requests to Strapi
        localStorage.setItem('jwt', res.jwt);
        localStorage.setItem('username', res.user.username);
        
        setText('You have been successfully logged in. You will be redirected in a few seconds...');
        setTimeout(() => window.location.href = '/', 3000); // Redirect to homepage after 3 sec
      })
      .catch(err => {
        setText('An error occurred, please see the developer console.')
      });
    }
  }, [params, navigate, location.search, params.providerName]);

  return (
    <div>
      <p>{text}</p>
    </div>
  );
};

export default ConnectCallback;