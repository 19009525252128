import { useEffect, useState } from "react"; 
import { makeRequest } from "../makeRequest";

const memoryCache = {};
const CACHE_LIFETIME = 1000; // Cache expires after 5 minutes (adjust as needed)

const useFetch = (url) => {
  const [data, setData] = useState(memoryCache[url] || null);
  const [loading, setLoading] = useState(!memoryCache[url]);
  const [error, setError] = useState(false);

  useEffect(() => {
    const cachedLocal = localStorage.getItem(url);
    const cachedTimestamp = localStorage.getItem(`${url}-timestamp`);

    if (memoryCache[url]) {
      setData(memoryCache[url]);
      setLoading(false);
      return;
    }

    if (cachedLocal && cachedTimestamp && Date.now() - cachedTimestamp < CACHE_LIFETIME) {
      const parsed = JSON.parse(cachedLocal);
      setData(parsed);
      memoryCache[url] = parsed;
      setLoading(false);
    } else {
      const fetchData = async () => {
        try {
          setLoading(true);
          const res = await makeRequest.get(url);
          setData(res.data.data);
          memoryCache[url] = res.data.data;
          localStorage.setItem(url, JSON.stringify(res.data.data));
          localStorage.setItem(`${url}-timestamp`, Date.now()); // Store timestamp of the fetch
        } catch (err) {
          setError(true);
        } finally {
          setLoading(false);
        }
      };
      fetchData();
    }
  }, [url]);

  return { data, loading, error };
};

export default useFetch;
