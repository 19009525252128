import React from 'react';
import { Link } from 'react-router-dom';
import './Card.scss';
import { IKImage } from 'imagekitio-react';

const Card = ({ item }) => {
    return (
        <Link className='link' to={`/product/${item.id}`}>
            <div className='card'>
                <div className='image'>
                    {item?.attributes?.isNew && <span>New Season</span>}
                    {item?.attributes?.img?.data?.map((item, key) => (
                        <IKImage
                            key={item.id}
                            src={item?.attributes?.url}
                            transformation={[
                                { width: 400, height: 400, quality: "auto", format: "auto" }
                            ]}
                            loading="lazy"
                            alt={item?.attributes?.alt || ""}
                            className={key === 0 ? "mainImg" : "secondImg"}
                        />
                    ))}
                </div>
                <h2>
                    {item?.attributes?.title}
                </h2>
                {item?.attributes?.price !== 0 && <div className="prices">
                    {/* {item?.attributes.price && <h3>£{item.oldPrice || item?.attributes.price + 20}</h3>} */}
                    {item?.attributes?.price ? `£${item.attributes.price}` : ''}
                </div>}
            </div>
        </Link>
    )
}

export default Card