import React, { createContext, useState, useContext } from 'react';

// Create the context
const OpenContext = createContext();

// Provider component
export const OpenProvider = ({ children }) => {
  const [open, setOpen] = useState(true);

  return (
    <OpenContext.Provider value={{ open, setOpen }}>
      {children}
    </OpenContext.Provider>
  );
};

// Custom hook for easy usage
export const useOpen = () => {
  const context = useContext(OpenContext);
  if (!context) {
    throw new Error('useOpen must be used within an OpenProvider');
  }
  return context;
};
