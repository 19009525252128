import React, { useState } from 'react'
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { AiOutlineArrowRight } from 'react-icons/ai';
import './Slider.scss';
import useFetch from '../../hooks/useFetch';
import { Puff } from 'react-loader-spinner';
import { IKImage } from 'imagekitio-react';

const Slider = () => {

    const [currentSlide, setCurrntSlide] = useState(0);

    const { data, loading, error } = useFetch(
        `/carousels/10?populate[carousel][fields][0]=url`
    );

    const prevSlide = () => {
        setCurrntSlide(currentSlide === 0 ? 2 : (prev) => prev - 1);
    };

    const nextSlide = () => {
        setCurrntSlide(currentSlide === 2 ? 0 : (prev) => prev + 1);
    };

    return (
        <div>
            {loading ? (
                <div className='loading mx-auto'>
                    <Puff
                        height="80"
                        width="80"
                        radius={1}
                        color="#757575"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass="justify-content-center"
                        visible={true}
                    />
                </div>
            ) : (
                <div className='slider'>
                    <div className='container' style={{ transform: `translateX(-${currentSlide * 100}vw)` }}>
                        {data?.attributes?.carousel?.data?.map((img) => (
                            <IKImage
                                src={img?.attributes?.url}
                                alt=""
                                key={img?.id}
                                transformation={[
                                    { width: 400, height: 400, quality: "auto", format: "auto" }
                                ]}
                                loading="lazy"
                            />
                        ))}
                    </div>
                    <div className='icons'>
                        <div className="icon" onClick={prevSlide}>
                            <AiOutlineArrowLeft />
                        </div>
                        <div className="icon" onClick={nextSlide}>
                            <AiOutlineArrowRight />
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

export default Slider