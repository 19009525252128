import React, { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { storeUser } from "../../helpers";
import './Login.scss';

const initialUser = { password: "", identifier: "" };

const Login = () => {
  const [user, setUser] = useState(initialUser);
  const navigate = useNavigate();
  const [mfaEnabled, setMfaEnabled] = useState(true);

  const handleUserChange = ({ target }) => {
    const { name, value } = target;
    setUser((currentUser) => ({
      ...currentUser,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault()
    const url = `${process.env.REACT_APP_API_URL}/auth/local`;
    try {
      if (user.identifier && user.password) {
        const { data } = await axios.post(url, user);
        if (data.jwt) {
          if (mfaEnabled) {
            toast.success("Logged in successfully!", {
              hideProgressBar: true,
            });
            storeUser(data);
            setUser(initialUser);
            navigate("/");
            window.location.reload();
          }
        }
      } else {
        toast.error("Email or password is required!", {
          hideProgressBar: true,
        });
      }
    } catch (error) {

      if (error.response.data.error.status === 400 || error.response.data.error.status === 500) {
        toast.error("Your email or password is invalid!", {
          hideProgressBar: true,
        });
      } else {
        const errorMessageDisplay = error.response.data.error.message;

        toast.error(
          errorMessageDisplay, {
            hideProgressBar: true,
          }
        );
      }
    }
  };

  return (
    <div className="login">
      <h1>
        <span>
          Sign in
        </span>
      </h1>
      <form>
        <div className="container">
          <div className="flex flex-col mb-6 md:w-full">
            <label className="font-bold text-lg mb-2" htmlFor="email">
              Email
            </label>
            <input
              type="email"
              name="identifier"
              value={user.identifier}
              onChange={handleUserChange}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="flex flex-col mb-6 md:w-full">
            <label className="font-bold text-lg mb-2" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={user.password}
              onChange={handleUserChange}
              placeholder="Enter password"
              required
            />
          </div>
          <button className="submitbtn" onClick={handleLogin}>
            Login
          </button>
        </div>
        <div className="container signin">
          <p>Don't have an account? <Link to="/register">Sign up</Link></p>
          <br />
          <p>Forgot Password? <Link to="/forgot-password">Click here</Link></p>
        </div>
      </form>
    </div>
  );
};

export default Login;