import {
  BrowserRouter, Route, Routes, Navigate,
} from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Navbar from "./components/Navbar/Navbar";
import Home from "./pages/Home/Home";
import Product from "./pages/Product/Product";
import Products from "./pages/Products/Products";
import "./app.scss";
import Register from "./pages/Register/Register";
import { ToastContainer } from "react-toastify";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import WardrobePage from "./pages/WardrobePage/WardrobePage";
import { userData } from './helpers';
import useWardrobe from "./pages/WardrobePage/useWardrobe";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Login from "./pages/Login/Login";
import ConnectCallback from "./components/ConnectRedirect/ConnectRedirect";
import Cookies from "js-cookie";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import Thankyou from "./pages/Thankyou/Thankyou";
import { IKContext, IKImage } from 'imagekitio-react';

const queryClient = new QueryClient()

function App() {

  const { id, username, jwt } = userData();

  const isLoggedIn = !!jwt;
  const { wardrobe, setWardrobe, addToWardrobe, removeFromWardrobe, wardrobeCount, setWardrobeCount } = useWardrobe(jwt);

  const user = Cookies.get('username') && Cookies.get('jwt');

  const urlEndpoint = process.env.REACT_APP_URL_ENDPOINT;

  return (
    <div className="app">
      <QueryClientProvider client={queryClient}>
        <IKContext urlEndpoint={urlEndpoint}>
          <BrowserRouter>
          <Navbar user={user} isLoggedIn={isLoggedIn} username={username} wardrobe={wardrobe} wardrobeCount={wardrobeCount} removeFromWardrobe={removeFromWardrobe} setWardrobeCount={setWardrobeCount} />
            <div className="layout-wrapper">
              <Routes>
                <Route path="/" element={<Home />} />
                  {/* {isLoggedIn ? <Home /> : <Login />} </Route> */}
                <Route path="/signin" element={isLoggedIn ? <Navigate replace to="/" /> : <Login />} />
                <Route path="/products/:id" element={<Products />} />
                <Route path="/product/:id" element={<Product addToWardrobe={addToWardrobe} userId={id} wardrobe={wardrobe} setWardrobe={setWardrobe} />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/thankyou" element={<Thankyou />} />
                <Route path="/connect/:providerName/redirect" element={<ConnectCallback token={jwt} />} />
                <Route
                  path="/wardrobe"
                  element={
                    <WardrobePage 
                      wardrobe={wardrobe}
                      removeFromWardrobe={removeFromWardrobe}
                    />
                  }
                />
              </Routes>
            </div>
          <Footer />
        </BrowserRouter>
        </IKContext>
        <ToastContainer />
      </QueryClientProvider>
    </div>
  );
}

export default App;
