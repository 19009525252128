import React from 'react';
import './FeaturedProducts.scss';
import useFetch from '../../hooks/useFetch';

const FeaturedProductsDesc = ({}) => {

  const {data} = useFetch(
    `/featured`
  );

  return (
    <p>
       {data?.attributes?.description}
    </p>
  )
}

export default FeaturedProductsDesc