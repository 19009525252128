import React, { useState } from 'react'
import Card from '../Card/Card'
import "./List.scss"
import useFetch from '../../hooks/useFetch'
import { Puff } from 'react-loader-spinner'

const List = ({subCats, maxPrice, sort, catId, query}) => {

  const {data, loading, error} = useFetch(
    `/products?populate=*&[filters][categories][id]=${catId}${subCats.map(
      (item) => `&[filters][sub_categories][id][$eq]=${item}`)}&[filters][price][$lte]=${maxPrice}&sort=price:${sort}`
  );

  const [visible, setVisible] = useState(20);

  const showMoreProducts = () => {
    setVisible(prevValue => prevValue + 20);
  }

  const filteredData = data?.filter((item) => item?.attributes?.title?.toLowerCase()?.includes(query));
  
  return (
    <>
      <div className='list'>
        {filteredData?.slice(0, visible)?.map(item => (
          <Card item={item} key={item?.id} />
        ))}
      </div>
      
      {/* Only show the "Load More" button if there are more products to load */}
      {filteredData?.length > visible && (
        <button
          className="load-more-btn"
          onClick={showMoreProducts}
        >
          Load More
        </button>
      )}
    </>
  )
}

export default List
