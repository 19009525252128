import { useState } from 'react';
import { setToken } from '../../lib/auth';
import { fetcher } from '../../lib/api';
import { Link, useNavigate } from "react-router-dom";
import './Register.scss';
import { toast } from 'react-toastify';
import axios from 'axios';
import emailjs from '@emailjs/browser'; // Make sure this is imported!

const initialUser = { email: "", password: "", username: "" };
const Register = () => {
  const [user, setUser] = useState(initialUser);
  const navigate = useNavigate();

  const signUp = async (e) => {
    e.preventDefault()
    try {
      const url = `${process.env.REACT_APP_API_URL}/auth/local/register`;
      if (user.username && user.email && user.password) {
        const res = await axios.post(url, user);
        if (!!res) {
          const templateParams = {
            user_name: user.username,
            cta_link: 'https://theartfulstylist.co.uk',
            email: user.email,      // ✅ Add inside here!
          };

          // Send welcome email via EmailJS
          await emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_WELCOME_TEMPLATE_ID,
            templateParams,
            process.env.REACT_APP_EMAILJS_PUBLIC_KEY
          );

          toast.success("Registered successfully! Please check your email to confirm your account.", {
            hideProgressBar: true,
          });
          setUser(initialUser);
          navigate("/signin");
        }
      } else {
        toast.error("Email, password or username is required!", {
          hideProgressBar: true,
        });
      }
    } catch (error) {

      const errorMessageDisplay = error?.response?.data?.error?.message;

      if(error?.response?.data?.error?.status === 500) {
        toast.error("Email or username already has an account with us please use new details.", {
          hideProgressBar: true,
        });
      } else {
        toast.error(
          errorMessageDisplay, {
            hideProgressBar: true,
          }
        );
      }
    }
  };

  const handleUserChange = ({ target }) => {
    const { name, value } = target;
    setUser((currentUser) => ({
      ...currentUser,
      [name]: value,
    }));
  };

  return (
    <div className="register">
      <h1>
        <span>
          Sign up
        </span>
      </h1>
      <form>
        <div className="container">
          <div className="flex flex-col mb-4 md:w-full">
            <label className="font-bold text-lg mb-2" htmlFor="username">
              Username
            </label>
            <input
              type="text"
              name="username"
              value={user.username}
              onChange={handleUserChange}
              placeholder="Enter your full name"
              required
            />
          </div>
          <div className="flex flex-col mb-4 md:w-full">
            <label className="font-bold text-lg mb-2" htmlFor="email">
              Email
            </label>
            <input
              className="border-2 py-2 px-3"
              type="email"
              name="email"
              value={user.email}
              onChange={handleUserChange}
              placeholder="Enter your email"
              required
            />
          </div>
          <div className="flex flex-col mb-6 md:w-full">
            <label className="font-bold text-lg mb-2" htmlFor="password">
              Password
            </label>
            <input
              type="password"
              name="password"
              value={user.password}
              onChange={handleUserChange}
              placeholder="Enter password"
              required
            />
          </div>
          <button
            className="submitbtn"
            type="submit"
            onClick={signUp}
          >
            Submit
          </button>
        </div>
        <div className="container signin">
          <p>Already have an account? <Link to="/signin">Sign in</Link>.</p>
        </div>
      </form>
    </div>
  );
};

export default Register;