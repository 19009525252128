import React from 'react';
import './Categories.scss';
import { Link } from 'react-router-dom';
import useFetch from '../../hooks/useFetch';
import { Puff } from 'react-loader-spinner';
import { useOpen } from '../../context/OpenProvider';
import { IKImage } from 'imagekitio-react';

const Categories = () => {
    const { setOpen } = useOpen();
    const { data, loading } = useFetch(`/categories?populate=img&fields[0]=title`);

    const filteredData = data?.filter(item => item.id !== 52 && item.attributes.title !== "All");

    return (
        <div className='categories'>
            {loading ? (
                <div className='loading'>
                    <Puff
                        height="80"
                        width="80"
                        radius={1}
                        color="#757575"
                        ariaLabel="puff-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            ) : (
                <>
                    <div className={`${filteredData?.[3] || filteredData?.[4] || filteredData?.[5] ? 'col' : 'col-row'}`}>
                        {filteredData?.slice(0, 2).map((item) => (
                            <div className='row' key={item.id}>
                                <IKImage src={item?.attributes?.img?.data?.attributes?.url} alt="" />
                                <button onClick={() => setOpen(false)}>
                                    <Link className='link' to={`/products/${item.id}`} state={{ title: item?.attributes?.title }}>{item.attributes.title}</Link>
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className='col'>
                        {filteredData?.slice(2, 3).map((item) => (
                            <div className="row" key={item.id}>
                                <IKImage src={item?.attributes?.img?.data?.attributes?.url} alt="" />
                                <button onClick={() => setOpen(false)}>
                                    <Link className='link' to={`/products/${item.id}`} state={{ title: item?.attributes?.title }}>{item.attributes.title}</Link>
                                </button>
                            </div>
                        ))}
                    </div>
                    {(filteredData?.[3] || filteredData?.[4] || filteredData?.[5]) && (
                        <div className='col col-l'>
                            <div className="row">
                                {filteredData?.slice(3, 5).map((item) => (
                                    <div className="col" key={item.id}>
                                        <div className="row">
                                            <IKImage src={item?.attributes?.img?.data?.attributes?.url} alt="" />
                                            <button onClick={() => setOpen(false)}>
                                                <Link className='link' to={`/products/${item.id}`} state={{ title: item?.attributes?.title }}>{item.attributes.title}</Link>
                                            </button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {filteredData?.slice(5, 6).map((item) => (
                                <div className="row" key={item.id}>
                                    <IKImage 
                                        src={item?.attributes?.img?.data?.attributes?.url} 
                                        alt="" 
                                        transformation={[
                                            { width: 400, height: 400, quality: "auto", format: "auto" }
                                        ]}
                                        loading="lazy"
                                    />
                                    <button onClick={() => setOpen(false)}>
                                        <Link className='link' to={`/products/${item.id}`} state={{ title: item?.attributes?.title }}
                                        >{item.attributes.title}</Link>
                                    </button>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

export default Categories